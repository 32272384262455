/* Marquee Script */

document.addEventListener("DOMContentLoaded", function() {
    var marquees = document.querySelectorAll('.marquee');
    
    marquees.forEach(function(marquee) {
        var marqueeItems = marquee.querySelectorAll('div.marquee-item');
        
        marqueeItems.forEach(function(item) {
            var existingItems = marquee.querySelectorAll('div.marquee-item').length;
            
            // Füge nur hinzu, wenn weniger als 3 vorhanden sind
            if (existingItems < 3) {
                var itemsToAdd = 3 - existingItems;
                for (var i = 0; i < itemsToAdd; i++) {
                    var clone = item.cloneNode(true);
                    marquee.appendChild(clone);
                }
            }
        });
    });
});
